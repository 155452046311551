import * as React from "react"
import styled from "styled-components"
import { successColor } from "../../static/styles/colors"
import Image from "../common/heroImage"
import { FaArrowDown } from "@react-icons/all-files/Fa/FaArrowDown"
import { Underline, ButtonPrimary } from "../common/styledComponentsHelper"

const EventHeroSection = ({
  desktopImg,
  mobileImg,
  headlineText,
  responsiveHeadline,
  eventDate,
  eventTime,
  eventDuration,
  captionText,
  bgImage,
}) => {
  const BgWallpaper = styled.div`
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: cover;
  `

  return (
    <div className="container-fluid overflow-hidden">
      <BgWallpaper className="row flex-lg-row-reverse align-items-center">
        <div className="col-lg-7">
          <div className="container">
            <div className="mb-5">
              <Underline className="display-4 fw-bold d-none d-sm-block lh-1 text-uppercase text-light">
                {headlineText}
              </Underline>
              <Underline className="display-4 fw-bold lh-1 d-sm-none text-uppercase text-light">
                {responsiveHeadline}
              </Underline>
            </div>
            <div className="text-uppercase text-light pt-4 mb-0">
              <div
                style={{ lineHeight: "0.9" }}
                className="text-success display-4 ps-3 ms-3"
              >
                <div className="fw-bold">SONNTAG</div>
                {eventDate}
                <div className="fs-5 lh-sm">
                  {eventTime}
                  <br />
                  {eventDuration}
                </div>
              </div>
              <VerticallyContainer className="fs-5 text-success">
                Save the date
              </VerticallyContainer>
            </div>
          </div>
          <div className="container mb-lg-0 mb-5">
            <div className="row">
              <div className="col-md-8 col-12 mb-3">
                <p className="text-uppercase text-light">{captionText}</p>
                <ButtonPrimary>
                  <a href="#registration" className="align-items-center d-flex">
                    Jetzt kostenlos Anmelden
                    <FaArrowDown className="ms-3" color={successColor} />
                  </a>
                </ButtonPrimary>
              </div>
              <div className="col-md-4 col-12">
                <a
                  href="https://www.provenexpert.com/fachhandwerk360-dreier-herber-gbr/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                  title="Kundenbewertungen &amp; Erfahrungen zu Fachhandwerk360 Dreier & Herber GbR. Mehr Infos anzeigen."
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                  className="d-md-flex justify-content-end"
                >
                  <img
                    src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                    alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                    style={{ border: "0", width: "60%", minWidth: "125px" }}
                    className="d-none d-md-block"
                  />
                  <img
                    src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                    alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                    style={{ border: "0", width: "157px" }}
                    className="d-md-none d-block"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 p-0">
          <Image
            alt="Hero Banner"
            filenameDesktop={desktopImg}
            filenameMobile={mobileImg}
            style={{
              filter: `drop-shadow(10px 0px 25px ${successColor})`,
            }}
          />
        </div>
      </BgWallpaper>
    </div>
  )
}

const VerticallyContainer = styled.div`
  padding-left: 3px;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  @media screen and (max-width: 1400px) {
    font-size: 1rem !important;
  }
`

export default EventHeroSection
